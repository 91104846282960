import Hero from '../sections/osintbook/Hero';
import KeyPoints from '../sections/osintbook/KeyPoints';
import AboutAuthor from '../sections/osintbook/AboutAuthor';
import CallToAction from '../sections/osintbook/CTA';
import { useEffect } from 'react';

function InvestigatorsGuideToOsint() {

  useEffect(() => {
    document.title = "Investigators Guide To Osint"
  })

  return (
    <div>
      <Hero />
      <KeyPoints />
      <AboutAuthor />
      <CallToAction />
    </div>
  );
}

export default InvestigatorsGuideToOsint;