import Hero from '../sections/thinkbeforebook/Hero';
import KeyPoints from '../sections/thinkbeforebook/KeyPoints';
import AboutAuthor from '../sections/thinkbeforebook/AboutAuthor';
import CallToAction from '../sections/thinkbeforebook/CTA';
import { useEffect } from 'react';

function ThinkBeforeYouClick() {
  
  useEffect(() => {
    document.title = "Think Before You Click"
  })

  return (
    <div>
      <Hero />
      <KeyPoints />
      <AboutAuthor />
      <CallToAction />
    </div>
  );
}

export default ThinkBeforeYouClick;