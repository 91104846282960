import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fname: "",
    femail: "",
    fphone: "",
    fmessage: "",
    fbook: "Investigators Guide To Osint",
  });

  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const API_PATH = "https://books.osint.co.za/static/mail/mail.php";
  const RECAPTCHA_SITE_KEY = "6Lckx0YpAAAAAKE-KN5pSOvUl1BKzPbILN30Tkep";

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert("Please complete the reCaptcha verification.");
      return;
    }

    const postData = {
      ...formData,
      "g-recaptcha-response": recaptchaValue,
    };

    axios({
      method: "post",
      url: API_PATH,
      headers: { "content-type": "application/json" },
      data: postData,
    })
      .then((result) => {
        setMailSent(result.data.sent);
        console.log(postData);
        alert("Thank you for contacting us. We will be in contact as soon as possible.");
        setFormDisabled(true);
      })
      .catch((error) => {
        setError(error.message);
        alert("Error: " + error.message);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <form role="form" onSubmit={handleFormSubmit} disabled={formDisabled}>
      <label htmlFor="fname">Name</label>
      <input
        id="fname"
        type="text"
        name="fname"
        required
        value={formData.fname}
        onChange={handleInputChange}
      />
      <label htmlFor="femail">Email</label>
      <input
        id="femail"
        type="email"
        name="femail"
        required
        value={formData.femail}
        onChange={handleInputChange}
      />
      <label htmlFor="fphone">Contact Number</label>
      <input
        id="fphone"
        type="text"
        name="fphone"
        value={formData.fphone}
        onChange={handleInputChange}
      />
       <label htmlFor="fbook">Book:</label>
      <select id="fbook" name="fbook" value={formData.fbook} onChange={handleInputChange} size="2">
        <option value="Investigators Guide To Osint">Investigators Guide To Osint</option>
        <option value="Think Before You Click">Think Before You Click</option>
      </select> 
      <label htmlFor="fmessage">Message</label>
      <textarea
        id="fmessage"
        rows="10"
        name="fmessage"
        value={formData.fmessage}
        onChange={handleInputChange}
      />

      <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptchaChange} />

      <input type="submit" value="Submit" disabled={formDisabled} />

    </form>
  );
};

export default ContactForm;
