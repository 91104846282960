import IconPhishing from "../../assets/icons/icon-phishing.svg"
import IconFakeWebsite from "../../assets/icons/icon-fake-website.svg"
import IconCyberBullying from "../../assets/icons/icon-cyber-bullying.svg"
import IconSocialEngineering from "../../assets/icons/icon-social-engineering.svg"
import IconHacking from "../../assets/icons/icon-hacking.svg"
import IconIdentityTheft from "../../assets/icons/icon-identity-theft.svg"
import IconBrandTheft from "../../assets/icons/icon-brand-theft.svg"
import IconPonziScheme from "../../assets/icons/icon-ponzi-scheme.svg"

function KeyPoints() {

    return (
        <div>
            <div className="card-container">
                <h2 className="title">Covering Critical Scam Topics</h2>
                <div className="card-row">
                    <div className="card">
                        <img alt="Icon Phishing" src={IconPhishing}/>
                        <h4>Phishing</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Fake Websites" src={IconFakeWebsite}/>
                        <h4>Fake Websites</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Cyber Bullying" src={IconCyberBullying}/>
                        <h4>Cyber Bullying</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Social Engineering" src={IconSocialEngineering}/>
                        <h4>Social Engineering</h4>
                    </div>
                </div>
                <div className="card-row">
                    <div className="card">
                        <img alt="Icon Hacking" src={IconHacking}/>
                        <h4>Hacking</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Identity Theft" src={IconIdentityTheft}/>
                        <h4>Identitiy Theft</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Brand Theft" src={IconBrandTheft}/>
                        <h4>Brand Theft</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Ponzi Schemes" src={IconPonziScheme}/>
                        <h4>Ponzi Schemes</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyPoints