import ContactForm from "./ContactForm"
import TakealotLogo from "../assets/TakeAlotLogoDark.svg";
import AmazonLogo from "../assets/AmazonLogoDark.svg";

function ContactUs() {
    return(
        <div className="contact-container">
            <div className="contact-details">
                <div>
                    <h3 className="title">Contact Us</h3>
                    <p>Prospective readers are encouraged to seamlessly initiate the book ordering process by filling out the contact form, ensuring a convenient and efficient means of securing their copy.</p>
                    {/* <p>Alternatively, readers can purchase a copy of the book on Takealot or Amazon</p> */}
                    <div className="contact-logo-container">
                    {/* <a href="https://www.takealot.com/" target="_blank" rel="noreferrer"><img src={TakealotLogo} alt="Takealot Logo"/></a>
                    <a href="https://www.amazon.com/" target="_blank" rel="noreferrer"><img src={AmazonLogo} alt="Amazon Logo"/></a> */}
                    </div>
                </div>
            </div>
            <div className="contact-form">
                <div>
                    <ContactForm/>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;