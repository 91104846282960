import IconSearch from "../../assets/icons/icon-searchengin.svg";
import IconFaceBook from "../../assets/icons/icon-facebook-square.svg";
import IconFingerprint from "../../assets/icons/icon-fingerprint.svg";
import IconMetaData from "../../assets/icons/icon-file-signature.svg";
import IconTools from "../../assets/icons/icon-tools.svg";
import IconGlobe from "../../assets/icons/icon-globe.svg";
import IconTable from "../../assets/icons/icon-table.svg";
import IconTie from "../../assets/icons/icon-user-tie.svg";

function KeyPoints() {

    return (
        <div>
            <div className="card-container">
                <h2 className="title">Covering Critical OSINT Topics</h2>
                <div className="card-row">
                    <div className="card">
                        <img alt="Icon Boolean Search Strings" src={IconSearch}/>
                        <h4>Boolean Search Strings</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Social Media Data Analysis" src={IconFaceBook}/>
                        <h4>Social Media Data Analysis</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Discovering a Targets Digital Footprint" src={IconFingerprint}/>
                        <h4>Discovering a Targets Digital Footprint</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Meta Data Analysis" src={IconMetaData}/>
                        <h4>Meta Data Analysis</h4>
                    </div>
                </div>
                <div className="card-row">
                    <div className="card">
                        <img alt="Icon Osint Toolsets & Methodology" src={IconTools}/>
                        <h4>Osint Toolsets & Methodology</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Complex & Geographic Searches" src={IconGlobe}/>
                        <h4>Complex & Geographic Searches</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Public & Private Datasets" src={IconTable}/>
                        <h4>Public & Private Datasets</h4>
                    </div>
                    <div className="card">
                        <img alt="Icon Due Diglligence" src={IconTie}/>
                        <h4>Due Diglligence</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KeyPoints