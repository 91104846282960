import AuthorImg from "../../assets/Craig-Profile.png";
import { BsLinkedin } from "react-icons/bs";

function AboutAuthor() {
    return (
        <div className="author-container">
            <div className="author-info">
                <div>
                    <h3 className="title">About the Author</h3>
                    <p>Craig Pedersen is a seasoned professional with over twenty years in the Information Technology, Commercial Security, and Intelligence and Investigations fields.</p>
                    <p>He is a Certified Fraud Examiner (CFE) and Certified Cyber Crime Investigator (CCCI).</p>
                    <p>In addition to writing, he lectures extensively to attorneys, advocates, auditors, and investigators across South Africa.</p>
                    <div className="author-socials">
                        <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjE9Pblo4yDAxWGUEEAHeakBd4QFnoECBAQAQ&url=https%3A%2F%2Fza.linkedin.com%2Fin%2Fcraig-pedersen-forensics&usg=AOvVaw1WXFOek7p2FM4EfhRlKrf3&opi=89978449" target="_blank" rel="noreferrer"><BsLinkedin /></a>
                    </div>
                </div>
            </div>
            <div className="author-image">
                <img src={AuthorImg} alt="Craig Profile"/>
            </div>
        </div>
    );
}

export default AboutAuthor