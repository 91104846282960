import { NavLink, useLocation } from "react-router-dom";
import TCGLogo from "../assets/TCGLogo.svg";
import TakealotLogo from "../assets/TakealotLogo.svg"
import AmazonLogo from "../assets/AmazonLogo.svg";


function Footer() {

    const location = useLocation();
    const path = location.pathname;

    let title = '';

    if (path === '/' || path === '/investigators-guide-to-osint') {
        title = (
            <h1>
                Investigators Guide To <span className="accent"><br />Open Source Intelligence</span>
            </h1>
        );
    } else if (path === '/think-before-you-click') {
        title = (
            <h1>
                <span className="accent">Think</span> Before You <span className="accent">Click</span>
            </h1>
        );
    }

    return (
        <div className="footer">
            <div className="site-identity">
                <img src={TCGLogo} alt="Digital Forensics Logo" />
                {title}
            </div>
            <div className="store-links">
                {/* <div className="takealot-logo">
                    <a href="https://www.takealot.com/" target="_blank" rel="noreferrer"><img src={TakealotLogo} alt="Takealot Logo" /></a>
                </div>
                <div className="amazon-logo">
                    <a href="https://www.amazon.com/" target="_blank" rel="noreferrer"><img src={AmazonLogo} alt="Amazon Logo" /></a>
                </div> */}
                <div className="nav-links">
                    <NavLink to="/investigators-guide-to-osint" className={({isActive}) => isActive ? "link-btn-active" : "link-btn"}>Investigators Guide To Osint</NavLink>
                    <NavLink to="/think-before-you-click" className={({isActive}) => isActive ? "link-btn-active" : "link-btn"}>Think Before You Click</NavLink>
                </div>
            </div>
        </div>
    );
}

export default Footer